import React from 'react';

function createContext<T>(name: `${string}Context`) {
  const context = React.createContext<Nullable<T>>(null);
  context.displayName = name;

  function useContext() {
    const ref = React.useContext(context);

    if (!ref && typeof ref !== 'boolean' && typeof ref !== 'number') {
      throw new Error(`use${name} must be used within ${name} provider.`);
    }

    return ref;
  }

  return [context.Provider, useContext, context] as const;
}

export default createContext;
