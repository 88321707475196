'use client';

import React from 'react';
import { useAppSelector } from '@providers->Redux/useReduxHooks';
import useLoginActions from '@application->login/useLogin.actions';
import type { RootState } from '@providers->Redux/store';
import type { StringInputProps } from '@root->global/inputs/Input.types';

function LoginInput({ component: Component, fragment }: Props) {
  const value = useAppSelector((state) => state.login[fragment]);
  const action = useLoginActions()[fragment as keyof Omit<ReturnType<typeof useLoginActions>, 'loading'>];

  const disabled = useAppSelector((state) => state.login.loading);

  return <Component value={value} setValue={action} disabled={disabled} />;
}

type Props = Readonly<{
  component: React.ComponentType<StringInputProps>
  fragment: keyof Omit<RootState['login'], 'loading'>
}>;

export default LoginInput;
