import React from 'react';
import type { ButtonStyleProps } from '@ui->Button/Button.styles';
import getButtonStyle from '@ui->Button/getButtonStyle';

type Core = { label: string, disabled?: boolean, icon?: string };

type SubmitType = { type: 'submit', onClick?: never };

type ClickType = { type?: 'button', onClick: () => void };

type Props = {
  // eslint-disable-next-line react/require-default-props
  buttonStyling?: string;
  // eslint-disable-next-line react/require-default-props
  classNameWrapper?: string;
} & Readonly<ButtonStyleProps & Core & (SubmitType | ClickType)>;

function Button({ classNameWrapper = '', buttonStyling = '', ...props }: Props) {
  const {
    type,
    onClick,
    label,
    disabled,
    icon,
    ...styles
  } = props;

  const styling = getButtonStyle(styles);
  if (type === 'submit') {
    return (
      <button disabled={disabled} type="submit" className={styling}>
        <span className={classNameWrapper}>{label}</span>
        <span>
          <img src={icon} alt="" className={buttonStyling} />
        </span>
      </button>
    );
  }

  return (
    <button disabled={disabled} type="button" onClick={!props.state ? onClick : () => null} className={styling}>
      <span className={classNameWrapper}>{label}</span>
      <span>
        <img src={icon} alt="" className={buttonStyling} />
      </span>
    </button>
  );
}

export default React.memo(Button);
