import type { IListenerModel } from '@services->State/Listener.model';
import type { IEventModel } from '@services->State/Event.model';
import createListener from '@services->State/createListener';
import createEvent from '@services->State/createEvent';

class ObserverService<T> {
  private readonly listeners: Set<IListenerModel<T>>;

  subscribe(listener: IListenerModel<T> | Array<IListenerModel<T>>): Fn {
    return Array.isArray(listener)
      ? this.subscribeMany(listener)
      : this.subscribeOne(listener);
  }

  externally(callback: Fns<IEventModel<T>>): Fn {
    return this.subscribeOne(createListener(callback));
  }

  protected broadcast(event: IEventModel<T>): void {
    this.listeners.forEach(broadcastEvent(event));
  }

  protected broadcastPayload(payload: T): void {
    this.broadcast(createEvent(payload));
  }

  protected subscribeOne(listener: IListenerModel<T>): Fn {
    this.connectOne(listener);
    return () => this.disconnectOne(listener);
  }

  protected subscribeMany(listeners: Array<IListenerModel<T>>): Fn {
    this.connectMany(listeners);
    return () => this.disconnectMany(listeners);
  }

  private connectOne(listener: IListenerModel<T>): void {
    this.listeners.add(listener);
  }

  private disconnectOne(listener: IListenerModel<T>): void {
    this.listeners.delete(listener);
  }

  private connectMany(listeners: Array<IListenerModel<T>>): void {
    listeners.forEach(this.connectOne.bind(this));
  }

  private disconnectMany(listeners: Array<IListenerModel<T>>): void {
    listeners.forEach(this.disconnectOne.bind(this));
  }

  constructor() {
    this.listeners = new Set<IListenerModel<T>>();
  }
}

function broadcastEvent<T>(event: IEventModel<T>) {
  return function send(listener: IListenerModel<T>) {
    listener.broadcast(event);
  };
}

export default ObserverService;
