import type { IEventModel } from '@services->State/Event.model';

class ListenerModel<T> implements IListenerModel<T> {
  private readonly callback: Fns<IEventModel<T>>;

  broadcast(event: IEventModel<T>) {
    this.callback(event);
  }

  constructor(callback: Fns<IEventModel<T>>) {
    this.callback = callback;
  }
}

export interface IListenerModel<T> {
  broadcast(event: IEventModel<T>): void;
}

export default ListenerModel;
