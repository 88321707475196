'use client';

import Input from '@ui->Input/Input';
import type { StringInputProps } from '@root->global/inputs/Input.types';
import { StatesTransitionProps } from '@components->StatesTransition/StatesTransition';

function EmailInput({
  setValue: setEmail, value: email, disabled, syncing, error, success,
}: StringInputProps & StatesTransitionProps) {
  return (
    <Input
      label="E-mail"
      onChange={setEmail}
      value={email}
      id="email"
      name="email"
      disabled={disabled}
      type="email"
      autoComplete="email"
      required
      syncing={syncing ?? false}
      error={error ?? ''}
      success={success ?? ''}
    />
  );
}

export default EmailInput;
