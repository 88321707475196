async function setLoginCookie(accessToken: string) {
  return fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: accessToken }),
  });
}

export default setLoginCookie;
