class EventModel<T> implements IEventModel<T> {
  private readonly _payload: T;

  get payload(): T {
    return this._payload;
  }

  constructor(payload: T) {
    this._payload = payload;
  }
}

export interface IEventModel<T> {
  payload: T
}

export default EventModel;
