import React from 'react';
import { useAppDispatch } from '@providers->Redux/useReduxHooks';
import { setLogin } from '@application->login/login.reducer';

function useLoginActions() {
  const dispatch = useAppDispatch();

  function setEmail(value: string) {
    dispatch(setLogin({ fragment: 'email', value }));
  }

  function setPassword(value: string) {
    dispatch(setLogin({ fragment: 'password', value }));
  }

  function setLoading(value: boolean) {
    dispatch(setLogin({ fragment: 'loading', value }));
  }

  return {
    email: React.useCallback(setEmail, [dispatch]),
    password: React.useCallback(setPassword, [dispatch]),
    loading: React.useCallback(setLoading, [dispatch]),
  } as const;
}

export default useLoginActions;
