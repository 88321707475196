const buttonStyles = {
  variants: {
    primary: 'bg-primary hover:bg-primary-dark text-white',
    primary_with_icon: 'font-bold text-[14px] text-white bg-primary hover:bg-primary-dark p-[12px] flex relative pr-[35px]',
    secondary: 'bg-primary-100 hover:bg-primary-200 text-secondary',
    link: 'bg-transparent hover:text-primary-dark',
    plusicon: 'flex mt-[7px] font-exo font-bold text-[12px] text-secondary hover:text-primary px-[20px]',
    backicon: 'flex gap-[5px] flex-row-reverse items-center direction font-exo font-bold text-[12px] text-secondary-400 hover:text-primary p-0 bg-transparent mt-[0px] lg:mt-[30px]',
    absolute: 'w-full text-[10px] text-secondary-500 font-normal !font-exo !hover:bg-secondary-600 bottom-0 absolute !bg-light border-light_darker border-[1px] py-[5px]',
    none: '',
  },
  sizes: {
    none: '',
    xs: '',
    sm: 'text-[14px] font-bold font-exo',
    md: 'text-[11px] leading-[11px] p-[6px]',
    lg: 'text-[13px] leading-[13px] p-[12px]',
    xl: 'text-[16px] leading-[20px] px-[24px] py-[10px]',
  },
  states: {
    disabled: 'cursor-no-drop !bg-grey-3',
    loading: 'cursor-no-drop animate-pulse opacity-50',
  },
  common: 'cursor-pointer focus-visible:outline focus-visible:outline-0 focus-visible:outline-offset-0 transition-all duration-300 ease-in rounded-[10px] min-w-[90px]',
} as const;

type ButtonVariant = keyof typeof buttonStyles.variants;

type ButtonSize = keyof typeof buttonStyles.sizes;

type ButtonState = keyof typeof buttonStyles.states;

type ButtonStyleProps = Readonly<{
  className?: string;
  variant?: ButtonVariant | undefined;
  state?: ButtonState | undefined
  size?: ButtonSize | undefined;
}>;

export type {
  ButtonVariant,
  ButtonSize,
  ButtonStyleProps,
};

export default buttonStyles;
