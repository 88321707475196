'use client';

import React from 'react';
import useLoginActions from '@application->login/useLogin.actions';
import setLoginCookie from '@application->login/setLoginCookie';
import { useRouter } from '@root->navigation';
import clsx from 'clsx';
import { useAuthProvider } from '@application->Auth.provider';
import { useLoginMutation } from '@generated->anywhere';
import { useAppSelector } from '@providers->Redux/useReduxHooks';

function LoginForm({ children }: ElementChildren) {
  const input = useAppSelector((state) => ({
    email: state.login.email,
    password: state.login.password,
  }));

  const { loading: setLoading } = useLoginActions();
  const [login, { error }] = useLoginMutation();
  const { refresh, push } = useRouter();
  const authProvider = useAuthProvider();

  const submit = React.useCallback(async (event: React.FormEvent) => {
    event.preventDefault();

    setLoading(true);

    try {
      const { data } = await login({
        variables: {
          input,
        },
      });

      await setLoginCookie(data!.login!.access_token);
      authProvider.setState(data!.login!.access_token);
      await push('/cases');
      await refresh();
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [input, setLoading, refresh, push, login, authProvider]);

  return (
    <>
      <form
        onSubmit={submit}
        className={clsx('space-y-[24px]', {
          'mt-8': !!error,
        })}
      >
        {children}
      </form>
      {error && (
        <div className="py-2">
          <p className="text-primary-error text-[12px]">
            {
              error.message
            }
          </p>
        </div>
      )}
    </>
  );
}

export default LoginForm;
