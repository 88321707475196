import ObserverService from '@services->State/Observer.service';
import React from 'react';

class StateService<T> extends ObserverService<T> {
  private _state: T;

  get state(): T {
    return this._state;
  }

  setState(change: T | Fns<T, T>): void {
    this._state = change instanceof Function
      ? change(this._state)
      : change;
    this.broadcastChange();
  }

  mergeState(change: Partial<T>): void {
    if (!(this._state instanceof Object)) {
      throw new Error('StateService: mergeState() can only be used with objects');
    }

    this._state = { ...this._state, ...change };
    this.broadcastChange();
  }

  toggleState() {
    if (!(this._state instanceof Boolean)) {
      throw new Error('StateService: toggleState() can only be used with booleans');
    }

    this._state = !this._state as T;
    this.broadcastChange();
  }

  createParameters(selector?: never): Parameters<typeof React.useSyncExternalStore<T>>;

  createParameters<R>(selector: Fns<T, R>,): Parameters<typeof React.useSyncExternalStore<R>>;
  createParameters<R>(selector?: Fns<T, R>): Parameters<typeof React.useSyncExternalStore<R | T>> {
    const select = createSelector(this._state, selector);
    return [
      this.externally.bind(this),
      select,
      select,
    ];
  }

  private broadcastChange(): void {
    this.broadcastPayload(this._state);
  }

  constructor(defaultState: T) {
    super();
    this._state = defaultState;
  }
}

function createSelector<T, R = T>(state: T, selector?: Fns<T, R>) {
  return function select() {
    return selector ? selector(state) as R : state as T;
  };
}

export default StateService;
