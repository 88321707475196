'use client';

import StateService from '@services->State/State.service';
import React from 'react';
import createContext from '@helpers->client-only/createContext';

type Token = Nullable<string>;

const [Provider, useProvider] = createContext<StateService<Token>>('AuthContext');

function AuthProvider({ token, children }: Props) {
  const authInstance = React.useMemo(() => (
    new StateService(token)
  ), [token]);

  return (
    <Provider value={authInstance}>
      {children}
    </Provider>
  );
}

export { useProvider as useAuthProvider };

type Props = WithElementChildren<{ token: Token }>;

export default AuthProvider;
