import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

const loginSlice = createSlice({
  name: 'login',
  initialState: {
    email: '',
    password: '',
    loading: false,
  },
  reducers: {
    setLogin: (state, action: PayloadAction<JustFragment<typeof state>>) => ({
      ...state,
      [action.payload.fragment]: action.payload.value,
    }),
  },
});

export const {
  setLogin,
} = loginSlice.actions;

export default loginSlice.reducer;
