'use client';

import Button from '@ui->Button/Button';
import { useAppSelector } from '@providers->Redux/useReduxHooks';

function LoginSubmitButton() {
  const loading = useAppSelector((state) => state.login.loading);
  const disabled = useAppSelector((state) => Boolean(!state.login.email || !state.login.password));

  return (
    <Button
      type="submit"
      label={loading ? 'Loading ...' : 'Login'}
      size="xl"
      state={loading ? 'loading' : disabled ? 'disabled' : undefined}
      disabled={loading || disabled}
      className="flex items-center justify-center w-full"
    />
  );
}

export default LoginSubmitButton;
