'use client';

import Input from '@ui->Input/Input';
import { StringInputProps } from '@root->global/inputs/Input.types';
import { StatesTransitionProps } from '@components->StatesTransition/StatesTransition';

function PasswordCurrentInput({
  setValue, value, disabled, syncing, error, success,
}: StringInputProps & StatesTransitionProps) {
  return (
    <Input
      label="Password"
      onChange={setValue}
      value={value}
      id="password"
      name="password"
      disabled={disabled}
      type="password"
      autoComplete="current-password"
      required
      syncing={syncing ?? false}
      error={error ?? ''}
      success={success ?? ''}
    />
  );
}

export default PasswordCurrentInput;
