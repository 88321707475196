import React from 'react';
import clsx from 'clsx';

function Input<T extends string | number>({
  label,
  onChange,
  type = 'text',
  id,
  className,
  error,
  success,
  disabled,
  syncing,
  ...rest
}: Props<T>) {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number' || type === 'float') {
      onChange((type === 'float'
        ? parseFloat(e.target.value)
        : parseInt(e.target.value, 10)) as T);

      return;
    }

    onChange(e.target.value as T);
  };

  const styling = clsx(
    'block w-full rounded-[10px] border-[1px] border-light_darker text-secondary text-[14px] font-bold leading-[19px] px-[8px] py-[8px] placeholder:text-secondary-400 focus:outline-none focus:ring-0 focus:border-secondary disabled:cursor-not-allowed',
    className,
    {
      'ring-primary-error text-red-600': !!error,
    },
  );

  return (
    <div className="w-full">
      <label
        htmlFor={id}
        className={clsx(
          'block text-[12px] leading-[14px] text-secondary-400 font-bold',
          {
            'text-primary-error': !!error,
            'bg-transparent': !error,
          },
        )}
      >
        {label}
      </label>
      <div className="mt-[4px]">
        <input
          {...rest}
          id={id}
          type={type}
          className={styling}
          disabled={disabled}
          onChange={handleChange}
          autoComplete="awxqweqwe"
          data-lpignore="true"
        />
      </div>
    </div>
  );
}

type Core = {
  label: string
  id: string
  error?: string
  success?: string
  syncing?: boolean
};

type NumberInput = { type: 'number' | 'float' };

type TextInput = {
  type?: 'text' | 'password' | 'email' | 'tel' | 'date'
};

type Props<T extends string | number> = Readonly<Omit<
Core
& React.ComponentProps<'input'>, 'type' | 'value' | 'onChange'>
& (TextInput | NumberInput)
& { onChange: (value: T) => void, value: T }
>;

export default Input;
