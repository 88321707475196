import buttonStyles, { type ButtonStyleProps } from '@ui->Button/Button.styles';
import clsx from 'clsx';

function getButtonStyle({
  className,
  size = 'none',
  variant = 'primary',
  state,
}: ButtonStyleProps) {
  return clsx(
    buttonStyles.common,
    buttonStyles.variants[variant],
    buttonStyles.sizes[size],
    state && buttonStyles.states[state],
    className,
  );
}

export default getButtonStyle;
